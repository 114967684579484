/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Header from "./header/header"
import Footer from "./footer/footer"
import SEO from "./seo";
import Modal from "./blocks/modal";

const Layout = (props) => (
    <>
        <Header pageUrl={props.pageUrl}/>
        <SEO description={props.description} lang={'ru'} title={props.title}/>
        {props.children}
        <Footer/>
        <Modal display={props.modalOpen}
               modalClose={props.modalClose}
               content={props.content}
               buttonColor={props.buttonColor}/>
    </>
)

export default Layout
