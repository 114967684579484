import React from "react"
import {Link} from "gatsby";

const MenuItem = (props) => (
    <li className="nav-header__item"
        onMouseEnter={(e) => props.itemHover(e, 'enter')}
        onMouseLeave={(e) => props.itemHover(e, 'leave')}>
        <Link to={props.item.url}
              className={`nav-header__link ${("/"+(props.pageUrl.split("/").filter(el => el !== "" ).shift())+"/" === props.item.url) ? 'nav-header__item_active' : ''}`}>
            <div dangerouslySetInnerHTML={{__html: props.item.svgIcon}}/>
            <p className="nav-header__title" dangerouslySetInnerHTML={{__html: (props.item.title).replace(' ', '<br>')}}/>
        </Link>
    </li>
)

export default MenuItem