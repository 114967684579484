import React from "react"
import {graphql, Link, StaticQuery} from "gatsby"
import marker from "../../data/images/map-marker.png"
import mail2 from "../../data/images/mail-2.png"


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.data = props.data.dataJson.footer;
    }

    bitrix(w, d, u) {
        if (!d.querySelector('.bx-crm-widget-form-config-wrapper')) {
            var s = d.createElement('script');
            s.async = true;
            s.src = u + '?' + (Date.now() / 60000 | 0);
            var h = d.getElementsByTagName('script')[0];
            h.parentNode.insertBefore(s, h);
        }
    }

    componentDidMount() {
        this.bitrix(window, document, 'https://cdn.bitrix24.by/b10524977/crm/site_button/loader_1_ern84l.js');
    }

    render() {
        return (
            <footer className="footer container-fluid">
                <div className="container">
                    <div className="row">
                        <div className="footer__logo col-md-2">
                            <Link to={this.data.logo.url}
                                  dangerouslySetInnerHTML={{__html: this.data.logo.desktopImage}}/>
                        </div>
                        <div className="footer__phones col-md-4">
                            <div className="footer__phones-line">
                                <img src={this.data.contacts.phone1.icon.publicURL} alt="a1"/>
                                <a href={this.data.contacts.phone1.link}>{this.data.contacts.phone1.number}</a>
                            </div>
                            <div className="footer__phones-line">
                                <img src={this.data.contacts.phone2.icon.publicURL} alt="mts"/>
                                <a href={this.data.contacts.phone2.link}>{this.data.contacts.phone2.number}</a>
                            </div>
                            <div className="footer__phones-line">
                                <img src={this.data.contacts.phone3.icon.publicURL} alt="tel"/>
                                <a href={this.data.contacts.phone3.link}>{this.data.contacts.phone3.number}</a>
                            </div>
                            <div className="footer__phones-line">
                                <img src={this.data.contacts.email.icon.publicURL} alt="mail"/>
                                <a href={this.data.contacts.email.link}>{this.data.contacts.email.text}</a>
                            </div>
                        </div>
                        <div className="footer__address col-md-3">
                            <p className="footer__address-text"
                               dangerouslySetInnerHTML={{__html: this.data.address.text}}/>
                            <Link to={this.data.address.link} className="arrow-link">
                                <p className="arrow-link__title">Как проехать</p>
                                <div className="arrow-link__container">
                                    <div className="arrow-link__start"/>
                                    <img
                                        className="arrow-link__icon"
                                        src={marker}
                                        alt="icon"
                                    />
                                    <div className="arrow-link__end">
                                        <div className="arrow-link__tip"/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="footer__social col-md-3">
                            <p className="footer__social-title">{this.data.socials.title}</p>
                            <p className="footer__social-icons">
                                {this.data.socials.icons.map((el, index) => <a href={el.link} target="_blank"  key={index}><img
                                    src={el.img.publicURL} alt={el.alt}/></a>)}
                            </p>
                            <Link to="/company/" className="arrow-link">
                                <p className="arrow-link__title">Напишите нам</p>
                                <div className="arrow-link__container">
                                    <div className="arrow-link__start"/>
                                    <img className="arrow-link__icon" src={mail2} alt="icon"/>
                                    <div className="arrow-link__end">
                                        <div className="arrow-link__tip"/>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>)
    }
}

export default props => (
    <StaticQuery
        query={graphql` 
           query FooterQuery {
              dataJson {
                footer {
                  address {
                    link
                    text
                  }
                  contacts {
                    email {
                      link
                      text
                      icon {
                        publicURL
                      }
                    }
                    phone1 {
                      link
                      number
                      icon {
                        publicURL
                      }
                    }
                    phone2 {
                      link
                      number
                      icon {
                        publicURL
                      }
                    }
                    phone3 {
                      link
                      number
                      icon {
                        publicURL
                      }
                    }
                  }
                  logo {
                    desktopImage
                    url
                  }
                  socials {
                    link
                    title
                    icons {
                      alt
                      link
                      img {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
            `}
        render={data => <Footer data={data} {...props} />}
    />
);