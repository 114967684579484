import React from "react"

const Topbar = (props) => (
    <div className="topbar">
      <div className="container">
        <div className="row justify-content-end">
          <div className="phone-block col-sm-10 col-xl-8">
            <div className="phone-block__phones-line">
              <img src={props.data.phone1.icon.publicURL} alt="phone" />
              <a href={props.data.phone1.link}>{props.data.phone1.number}</a>
              <img src={props.data.phone2.icon.publicURL} alt="phone" />
              <a href={props.data.phone2.link}>{props.data.phone2.number}</a>
              <img src={props.data.phone3.icon.publicURL} alt="phone" />
              <a href={props.data.phone3.link}>{props.data.phone3.number}</a>
            </div>
          </div>
          <div className="col-md-2 d-flex justify-content-center">
            <div className="lang-block">
              <p className="lang-block__lable">{props.data.langBlock[0].lang}</p>
              <img
                  src={props.data.langBlock[0].icon.publicURL}
                  alt="lang"
                  className="lang-block__img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
)

export default Topbar
