import React from "react"
import {Link} from "gatsby";
import MobileNavItem from "./mobile-nav-item";

class MobileNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: false,
            mobileLogo: this.props.logo.mobileImage.black.publicURL
        };
        this.menuToggle = this.menuToggle.bind(this)
    }

    menuToggle() {
        (this.state.isToggleOn) ?
            this.setState({isToggleOn: false, mobileLogo: this.props.logo.mobileImage.black.publicURL}) :
            this.setState({isToggleOn: true, mobileLogo: this.props.logo.mobileImage.white.publicURL});
    }


    render() {
        return (
            <div
                className={(this.state.isToggleOn) ? "mobile-nav container-fluid mobile-nav_is-active" : "mobile-nav container-fluid"}>
                <div className="mobile-nav__topbar row">
                    <div className="mobile-nav__navbar mobile-nav__navbar-expanded">
                        <div className="logo mobile-nav__col">
                            <Link to="/">
                                <img src={this.state.mobileLogo} alt="СофтИндустрия ООО"/>
                            </Link>
                        </div>
                        <div className="lang-block mobile-nav__col">
                            <p className="lang-block__lable">РУС</p>
                            <img src={this.props.topbar.langBlock[0].icon.publicURL} alt="lang" className="lang-block__img"/>
                        </div>
                        <div className="mobile-nav__col">
                            <button className={(this.state.isToggleOn) ? "c-hamburger is-active" : "c-hamburger"}
                                    onClick={() => this.menuToggle()}>
                                <span>toggle menu</span>
                            </button>
                        </div>
                    </div>
                    <div className="mobile-nav__container">
                        <ul>
                            {this.props.menu.map((el, index) => <MobileNavItem key={index} item={el}/> )}
                        </ul>
                        <div className="mobile-nav__hr"/>
                        <div className="mobile-nav__phones-line">
                            <img src={this.props.topbar.phone1.icon.publicURL} alt="icon"/>
                            <a href={this.props.topbar.phone1.link}>{this.props.topbar.phone1.number}</a>
                        </div>
                        <div className="mobile-nav__phones-line">
                            <img src={this.props.topbar.phone2.icon.publicURL} alt="icon"/>
                            <a href={this.props.topbar.phone2.link}>{this.props.topbar.phone2.number}</a>
                        </div>
                        <div className="mobile-nav__phones-line">
                            <img src={this.props.topbar.phone3.icon.publicURL} alt="icon"/>
                            <a href={this.props.topbar.phone3.link}>{this.props.topbar.phone3.number}</a>
                        </div>
                        <div className="mobile-nav__phones-line">
                            <img src={this.props.topbar.email.icon.publicURL} alt="icon"/>
                            <a href={this.props.topbar.email.link}>{this.props.topbar.email.text}</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileNav