import React from "react"
import DropDownMenuBlock from "./drop-down-menu-block";
import {StaticQuery, graphql} from "gatsby";


const DropDownMenu = (props) => (
    <StaticQuery
        query={graphql`
        query dropdownData {
          dataJson {
            pages {
              sections {
                name
                title
                blocks {
                  h4
                  link
                  icon {
                    publicURL
                  }
                }
              }
            }
          }
        }
            `}
        render={data => (
            <div className="dropdown container"
                 style={{"opacity": (props.opacity) ? '1' : '0', "display": (props.display) ? 'block' : 'none'}}
                 onMouseEnter={(e) => props.itemHover(e, 'enter')}
                 onMouseLeave={(e) => props.itemHover(e, 'leave')}>
                <div className="row">
                    <div className="col">
                        <h2>{data.dataJson.pages[props.id].sections.map(el => (el.name === 'submenu') ? el.title : '')}</h2>
                    </div>
                </div>
                <div className="row">
                    {data.dataJson.pages[props.id].sections.map(el => (el.name === 'submenu') ?
                        el.blocks.map((el, key) => <DropDownMenuBlock key={key}
                                                               link={el.link || ''}
                                                               icon={el.icon || ''}
                                                               h4={el.h4 || ''}/>
                        ) : '')}
                </div>
            </div>
        )}
    />

)

export default DropDownMenu
