import {graphql, Link, StaticQuery} from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Topbar from "./topbar"
import Navbar from "./navbar"
import MobileNav from "./mobile-nav"

const Header = (props) => (
    <StaticQuery
        query={graphql`
            query HeaderQuery {
              dataJson {
                header {
                  logo {
                    desktopImage
                    mobileImage {
                      black {
                        publicURL
                      }
                      white {
                        publicURL
                      }
                    }
                    url
                  }
                  menu {
                    svgIcon
                    title
                    url
                  }
                  topbar {
                    langBlock {
                      icon {
                        publicURL
                      }
                      lang
                    }
                    phone1 {
                      link
                      number
                      icon {
                        publicURL
                      }
                    }
                    phone2 {
                      link
                      number
                      icon {
                        publicURL
                      }
                    }
                    phone3 {
                      link
                      number
                      icon {
                        publicURL
                      }
                    }
                    email {
                      link
                      text
                      icon {
                        publicURL
                      }
                    }
                  }
                }
              }
            }`
        }
        render={data => (
            <div>
                <header>
                    <Topbar data={data.dataJson.header.topbar}/>
                    <Navbar pageUrl={props.pageUrl} logo={data.dataJson.header.logo}  menu={data.dataJson.header.menu}/>
                </header>
                <MobileNav logo={data.dataJson.header.logo}  menu={data.dataJson.header.menu} topbar={data.dataJson.header.topbar}/>
            </div>
        )}
    />

)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
