import React from "react"
import {Link} from "gatsby"
import DropDownMenu from "./drop-down-menu";
import MenuItem from "./menu-item";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.pageUrl,
            displayDropDown: false,
            opacityDropDown: false,
            hoverId: '1'
        };
        this.timerId = 0;
        this.itemHover = this.itemHover.bind(this)
    }

    itemHover(e, action) {
        if (e.currentTarget.children[0].pathname) {
            for (let i = 0; i < this.props.menu.length; i++) {
                if (e.currentTarget.children[0].pathname === this.props.menu[i].url) {
                    this.setState({hoverId: i + 1});
                }
            }
        }

        if (action === 'leave') {
            clearTimeout(this.timerId);
            this.setState({opacityDropDown: false});
            this.timerId = setTimeout(() => this.setState({displayDropDown: false}), 300);
        }

        if (e.currentTarget.className === 'nav-header__item' && action === 'enter' || e.currentTarget.style.opacity === '1' && action === 'enter') {
            clearTimeout(this.timerId);
            this.setState({displayDropDown: true});
            this.timerId = setTimeout(() => this.setState({opacityDropDown: true}), 0);

        }
    }

    render() {
        return (
            <div className="navbar">
                <div className="container">
                    <div className="row">
                        <div className="logo col-2">
                            <Link to={this.props.logo.url}
                                  dangerouslySetInnerHTML={{__html: this.props.logo.desktopImage}}/>
                        </div>
                        <nav className="col-10">
                            <ul className="nav-header">
                                {this.props.menu.map((el, index) => <MenuItem
                                    key={index}
                                    id={index}
                                    pageUrl={this.props.pageUrl}
                                    item={el}
                                    itemHover={this.itemHover}/>)}
                            </ul>
                        </nav>
                    </div>
                </div>
                <DropDownMenu opacity={this.state.opacityDropDown} display={this.state.displayDropDown}
                              itemHover={this.itemHover} id={this.state.hoverId}/>
            </div>
        )
    }
}

export default Navbar