/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  const schemaOrgOrganization = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    "name": "ООО СофтИндустрия",
    "url": "https://softindustry.by",
    "logo": "src/data/images/logo.png",
    "image": "src/data/images/logo.png",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "пер. Софьи Ковалевской, 60, офис 703",
      "addressLocality": "Минск",
      "addressRegion": "Минская область",
      "postalCode": "220014",
      "addressCountry": "Беларусь"
    },
    "contactPoint": [{
      "@type": "ContactPoint",
      "telephone": "+375 17 388-80-80",
      "contactType": "sales"
    },{
      "@type": "ContactPoint",
      "telephone": "+375 33 386-80-80",
      "contactType": "sales"
    },{
      "@type": "ContactPoint",
      "telephone": "+375 29 386-80-80",
      "contactType": "sales"
    }]
  }


  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      script={[
        {
          "type": "application/ld+json",
          innerHTML: `${JSON.stringify(schemaOrgOrganization)}`
        }
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
