import React from "react"
import {Link} from "gatsby";

const MobileNavItem = (props) => (
    <li className="mobile-nav__item">
        <Link to={props.item.url} className="mobile-nav__link">
            <div dangerouslySetInnerHTML={{__html: props.item.svgIcon}}/>
            <p className="mobile-nav__title">{props.item.title}</p>
        </Link>
    </li>
)

export default MobileNavItem